import { coding, routine } from "@/conf/instructions";
import { credits } from "../conf/instructions";

export const messages = {
  title: "The Algorithm",
  credits: "By&nbsp;Yves&nbsp;Doumergue &&nbsp;Xavier&nbsp;Decoret",
  auth: {
    title: "Sign in",
    "sign-out": "Sign out",
    email: "Email",
    password: "Password",
    emailConfirm: "Confirm your email address",
    noAccountYet: "Don't have an account? Go to the ",
    signUpPage: "sign up page",
    alreadyHaveAnAccount: "Already have an account? Go to the ",
    signInPage: "sign in page",
    forgottenPassword: "Forgotten password?",
    "token-next-step": "Your activate code will be required at the next step",
    displayName: "First and last name",
    "sign-in": "WELCOME",
    "log-in": "Log in",
    "sign-up": "Register",
    separator: "Or",
    "forgotten-title": "PASSWORD RESET",
    "forgotten-send": "SEND RESET LINK",
    "forgotten-sent":
      "If your entry is properly validated, you will receive a one-time reset link. Please check your inbox now.",
    "back-to-login": "Back to login",
    "tier-sign-in": {
      google: "Sign in with Google",
      facebook: "Sign in with Facebook",
    },
    "tier-sign-up": {
      google: "Sign up with Google",
      facebook: "Sign up with Facebook",
    },
    create: "Create account",
    subscribe: "Get premium",
    "switch-to-sign-in": "I already have an account, sign in",
    "switch-to-sign-up": "New user registration",
  },
  learning: {
    routine: routine.en,
    coding: coding.en,
  },

  settings: {
    settings: "Instructions / Settings",
    account: "Account",
    "account-sub": "Manage your profile",
    general: "MISCELLANEOUS",
    "general-sub": "Initial setup",
    learn: "Learn",
    "learn-sub": "Dive into The Algorithm",
    configuration: "Configuration",
    "configuration-sub": "Finetune the experience",
    about: "About",
    "about-sub": "The Algorithm",

    language: "Language",

    routine: "Routine",
    "routine-sub": "An overview of the essentials",

    coding: "Coding",
    "coding-sub": "The mechanics behind the magic",

    videos: "Videos",
    "videos-sub": "Visual instruction",

    training: "training",
    "training-sub": "Build your coding accuracy and speed",
    delay: "AUTO-RESUME",
    "delay-explaination":
      "If activated, the trick resumes after x seconds after a card reveal",

    stack: "STACK SELECTION",
    "suits-order": "SUIT ORDER",
    layout: "layout",
    top: "Top",
    left: "Left",

    version: "version",
    contact: "contact",

    "third-step": "Third step",
    "second-step": "Second step",

    save: "save",
    cancel: "cancel",

    logout: "Log out",
    code: "TOKEN",
    email: "E-Mail",
    credits: "Credits",

    "full-credits": credits.en,
  },
  code: {
    title: "Access Portal",
    "your-code": "Enter your activation code",
    cancel: "Cancel",
    check: "AUTHORIZE",
    "no-card": "Need help?",
    error: {
      "invalid-code": "Invalid code",
      "no-code": "No code",
    },
    "find-a-resseler": "EMAIL APP SUPPORT",
  },

  guide: {
    "install-ios": `Press <i class="icon-export"></i>, and select Add to Home Screen <i class="icon-shareios"></i>.`,
    "install-android": `Press <i class="icon-more"></i>, and select Install App or Add to Home Screen.`,
  },

  contact: {
    "general-help": "General help",
    "token-help": "Token help",
    crediting: "Crediting",
  },
};
